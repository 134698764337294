import React from 'react';

import ggLogo from './img/gg.svg';
import './img/bullet.svg';
import './img/tape.svg';

import './gunbox.css';
import './gunguard.css';

const GunBox = ({children, bg}) => (
    <div className="gunbox">
        <div className="gunbox-container">
            <div className={bg ? "gunbox-photo" : "gunbox-paper"} style={bg ? {backgroundImage: 'url(' + bg + ')'} : {}}>
                {children}
            </div>
        </div>
        <div className="gunbox-nw"></div><div className="gunbox-ne"></div><div className="gunbox-sw"></div><div className="gunbox-se"></div>
    </div>
);

const PhotoBox = ({children, src, alt}) => (
    <div className="gunbox">
        <div className="gunbox-container">
            <img src={src} alt={alt} />
            <div className="gunbox-filler">
                {children}
            </div>
        </div>
        <div className="gunbox-nw"></div><div className="gunbox-ne"></div><div className="gunbox-sw"></div><div className="gunbox-se"></div>
    </div>
);

export default function GunGuard() {
    return (
        <div>
            <div id="banner">
                <h1><img src={ggLogo} alt="GunGuard" className="pure-img" /></h1>
                <h2>Ultimate safety of shooting ranges</h2>
            </div>
        
            <div className="pure-g">
                <div className="pure-u-1 pure-u-lg-3-5">
                    <GunBox>
                        <p>GunGuard is an unique electro-mechanical device that:</p>
                        <ul>
                            <li>Dramatically increases the safety of shooting ranges</li>
                            <li>Is lightweight, compact, and reliable solution for every firearm</li>
                            <li>Blocks the gun or rifle from firing in an unsafe direction</li>
                        </ul>
                    </GunBox>
                </div>
                <div className="pure-u-1 pure-u-lg-2-5">
                    <PhotoBox src="img/ph2.jpg" alt="Bartek receiving grand prize">
                        <p className="permanent-marker">First prize in a contest for the best startup</p>
                    </PhotoBox>
                </div>
            </div>

            <div className="pure-g">
                <div className="pure-u-1 pure-u-lg-1-2">
                    <PhotoBox src="img/dyp.jpg" alt="Diplomas for GunGuard on INVENT ARENA 2024" />
                </div>
        
                <div className="pure-u-1 pure-u-lg-1-2">
                    <GunBox>
                        <p><b>Patents</b></p>
                        <ul>
                            <li>P.437086 &mdash; <a href="https://patents.google.com/patent/PL437086A1" target="_blank" rel="noreferrer" style={{color: '#000'}}>Device for securing firearms</a></li>
                        </ul>
                        <p><b>Patent applications</b></p>
                        <ul>
                            <li>P.444269 &mdash; Electromechanical trigger lock for guns</li>
                            <li>P.444321 &mdash; Firearm safety device using inductive loop</li>
                            <li>P.444269 &mdash; Audio and video pattern recognition in shooting sports</li>
                            <li>P.449095 &mdash; Electromechanical trigger lock for firearms</li>
                            <li>P.449288 &mdash; Application of inertial navigation to shooting sports</li>
                        </ul>
                    </GunBox>
                </div>
            </div>
        
            <div className="pure-g">
                <div className="pure-u-1 pure-u-md-1-3 pure-u-xl-1-5">
                    <PhotoBox src="img/ph1.jpg" alt="Working prototype" />
                </div>
                <div className="pure-u-1 pure-u-md-2-3 pure-u-xl-2-5">
                    <GunBox>
                        <p>Optional features:</p>
                        <ul>
                            <li>Define safe/unsafe zones</li>
                            <li>Define maximum pitch and roll angles</li>
                            <li>Limit the number of shoots from a particular location</li>
                        </ul>
                    </GunBox>
                </div>
                <div className="pure-u-1 pure-u-md-1 pure-u-xl-2-5">
                    <GunBox bg="img/bg1.jpg">
                        <p>Contact info:</p>
                        <p><a href="mailto:bartek@gunguard.eu" style={{color: '#FFF'}}>Bartłomiej Kurkowski &lt;bartek@gunguard.eu&gt;</a></p>
                        <p><a href="mailto:olek@gunguard.eu" style={{color: '#FFF'}}>Aleksander Mielczarek &lt;olek@gunguard.eu&gt;</a></p>
                    </GunBox>
                </div>
        
            </div>
                
            <footer>
                <p>All rights reserved Olek&Bartek, 2024</p>
            </footer>
        </div>
    );
}
